<template>
    <div class="grid grid-cols-12 gap-30 mt-30">
        <!-- Search filter -->
        <div class="col-span-12 md:col-span-7 lg:col-span-8 xl:col-span-9 flex items-center ml-30 mr-30 md:mr-30 relative h-48">
            <img
                class="absolute left-10 pl-10"
                :src="require('@/assets/images/icons/search.svg')"
                alt="passwordVisibility"
                width="30"
            >
            <input
                class="focus:outline-none rounded-lg bg-white pl-60 pr-16 input"
                :placeholder="$t('casesFilterPlaceholder')"
                v-model="filters.name.value"
                type="text"
            />
        </div>
        <!-- Add new case -->
        <div
            v-if="userTypeManager"
            class="col-span-12 md:col-span-5 lg:col-span-4 xl:col-span-3 flex justify-end items-center mr-30 ml-30 md:ml-0 relative"
        >
            <router-link
                class="button bg-purple-500 w-full text-white hover:bg-purple-600 flex items-center justify-center text-lg"
                :to='{name:"CaseAdd"}'
                @click.native="eraseErrors"
            >
                {{ $t('casesAddNewCaseLinkText') }}
            </router-link>
            <!-- Confirmation box for success messages -->
            <div
                class="fixed top-20 right-30 z-10000"
                ref="successBoxContainer"
            >
            </div>
        </div>
        <!-- Cases table -->
        <div class="col-span-12 mx-30 py-30 bg-white rounded-lg shadow">
            <!-- Filter cases buttons -->
            <div class="mb-30 ml-20 flex flex-col md:flex-row mr-20">
                <!-- Show all cases -->
                <button
                    class="filter-case font-bold px-20 py-7 md:rounded-l focus:outline-none hover:bg-purple-500 hover:text-white font-titillium-bold"
                    :class="{ active_filter : activeFilterCase === 1 }"
                    @click="filterAllCases(1)"
                >
                    {{ $t('casesFilterAllCasesButton') }}
                </button>
                <!-- Show registered cases -->
                <button
                    class="filter-case font-bold px-20 py-7 focus:outline-none hover:bg-purple-500 hover:text-white font-titillium-bold"
                    :class="{ active_filter : activeFilterCase === 2 }"
                    @click="filterRegisteredCases('registered', 2)"
                >
                    {{ $t('casesFilterRegisteredCasesButton') }}
                </button>
                <!-- Show in progres cases -->
                <button
                    class="filter-case font-bold px-20 py-7 focus:outline-none hover:bg-purple-500 hover:text-white font-titillium-bold"
                    :class="[{ active_filter : activeFilterCase === 3 }, (filterStaffCases !== true) ? (userTypeManager ? 'md:rounded-r' : '') : '']"
                    @click="filterInProgressCases('in progress', 3)"
                >
                    {{ $t('casesFilterInProgressCasesButton') }}
                </button>
                <!-- Show in logged in staff member cases -->
                <button
                    v-if="userTypeStaff"
                    class="filter-case font-bold px-20 py-7 md:rounded-r focus:outline-none hover:bg-purple-500 hover:text-white font-titillium-bold"
                    :class="{ active_filter : activeFilterCase === 4 }"
                    @click="filterYourCases('yours', 4)"
                >
                    {{ $t('casesFilterYourCasesButton') }}
                </button>
            </div>
            <!-- Cases info table -->
            <v-table
                class="table"
                :data="cases"
                :currentPage.sync="currentPage"
                :pageSize="pageSize"
                @totalPagesChanged="totalPages = $event"
                :hideSortIcons="true"
                :filters="filters"
            >
                <!-- Cases columns name -->
                <thead
                    class="thead"
                    slot="head"
                >
                    <!-- Case number column -->
                    <th class="md:w-1/12" style="padding-left: 20px;">#</th>
                    <!-- Victim name column -->
                    <th class="md:w-1/5 th">
                        <v-th
                            class="flex items-center justify-between flex-row-reverse"
                            sortKey="victim.full_name"
                        >
                            {{ $t('casesTabelColumn_1') }}
                        </v-th>
                    </th>
                    <!-- Case responsables column -->
                    <th class="md:w-1/5 th">
                        {{ $t('casesTabelColumn_2') }}

                    </th>
                    <!-- Case status column -->
                    <th class="md:w-1/5 th">
                        <v-th
                            class="flex items-center justify-between flex-row-reverse"
                            sortKey="status"
                        >
                            {{ $t('casesTabelColumn_3') }}
                        </v-th>
                    </th>
                    <!-- Case modified column -->
                    <th class="md:w-1/5 th">
                        <v-th
                            class="flex default-arrows items-center justify-between flex-row-reverse"
                            sortKey="modified_reversed"
                            defaultSort="desc"
                        >
                            {{ $t('casesTabelColumn_4') }}
                        </v-th>
                    </th>
                    <!-- Case registerd column -->
                    <th class="md:w-1/5 th">
                        <v-th
                            class="flex default-arrows items-center justify-between flex-row-reverse"
                            sortKey="registered_reversed"
                        >
                            {{ $t('casesTabelColumn_5') }}
                        </v-th>
                    </th>
                </thead>
                <!-- Cases info rows -->
                <tbody
                    id="table_body"
                    class="tbody"
                    slot="body"
                    slot-scope="{displayData}"
                >
                    <!-- Table row -->
                    <tr
                        class="border-b hover:bg-gray-50"
                        v-for="cases in displayData"
                        :key="cases.id"
                    >
                        <!-- Case number row -->
                        <td class="md:w-1/12 text-purple-600 font-titillium-bold font-bold">
                            <router-link :to="{ name: 'CaseDetails', params: { slug: `${cases.id}`} }">
                                <div class="flex items-center">
                                    <span class="th lg:hidden mr-7">#:</span>
                                    {{cases.id}}
                                </div>
                            </router-link>
                        </td>
                        <!-- Case victim name row -->
                        <td class="lg:w-1/5 text-gray-800 font-titillium-bold font-bold">
                            <router-link :to="{ name: 'CaseDetails', params: { slug: `${cases.id}`} }">
                                <div class="flex items-center">
                                    <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_1') }}:</span>
                                    {{ cases.victim.first_name }}&nbsp;{{ cases.victim.last_name }}
                                </div>
                            </router-link>
                        </td>
                        <!-- Case responsables row -->
                        <td class="p text-gray-800 flex">
                            <div class="flex items-center">
                                <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_2') }}:</span>
                                <div class="flex md:flex-wrap ">
                                    <!-- Show only three responsables -->
                                    <div
                                        v-for="(responsible,index) in cases.responsibles.slice(0,3)"
                                        :key="index"
                                    >
                                        <p class="w-36 h-36 m-4 flex items-center justify-center ml-4 rounded-full bg-purple-50 text-purple-600 font-titillium-bold font-bold">
                                            {{responsible.acronym}}
                                        </p>
                                    </div>
                                    <!-- If more than three responsables show responsables number left -->
                                    <p
                                        v-if="cases.responsibles.length > 3"
                                        class="w-36 h-36 flex m-4 items-center justify-center ml-4 rounded-full bg-purple-50 text-purple-600 font-titillium-bold font-bold"
                                    >+{{cases.responsibles.length - 3}}</p>
                                </div>
                            </div>
                        </td>
                        <!-- Case status row -->
                        <td class="lg:w-1/5">
                            <div class="flex items-center">
                                <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_3') }}:</span>
                                <div
                                    class="text-center rounded-full w-85 py-4 small"
                                    :class="BindCaseStatus(cases.status.toLowerCase())"
                                >
                                    <span v-if="cases.status === 'draft'">{{ $t('tableStatusDraft') }}</span>
                                    <span v-if="cases.status === 'registered'">{{ $t('tableStatusRegistered') }}</span>
                                    <span v-if="cases.status === 'in progress'">{{ $t('tableStatusInProgress') }}</span>
                                    <span v-if="cases.status === 'archived'">{{ $t('tableStatusInArchived') }}</span>
                                </div>
                            </div>
                        </td>
                        <!-- Case modified row -->
                        <td class="md:w-1/5 small text-gray-600">
                            <div class="flex items-center">
                                <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_4') }}:</span>
                                {{ cases.modified }}
                            </div>
                        </td>
                        <!-- Case registered row -->
                        <td class="md:w-1/5 small text-gray-600">
                            <div class="flex items-center">
                                <span class="th lg:hidden mr-7">{{ $t('casesTabelColumn_5') }}:</span>
                                {{ cases.registered }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </v-table>
            <!-- Pagination -->
            <div
                v-if="cases.length"
                class="flex items-center justify-between mt-30"
            >
                <div class="pl-10 p text-gray-800">{{page}}-{{pageSizeIncrement}} {{ $t('fromText') }} {{cases.length}} {{cases.length > 1 ? $t('fromCasesText') : $t('fromCasesTextSingle') }}</div>
                <div>
                    <smart-pagination
                        :currentPage.sync="currentPage"
                        :totalPages="totalPages"
                    />
                </div>
            </div>
            <div
                v-else
                class="bg-white rounded-lg shadow mb-30 p-20"
            >
                <p class="text-2xl opacity-85 tracking-tight text-gray-800 font-bold font-titillium-bold mb-20 md:mb-0">{{$t('noRecordsText')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SuccessBox from '@/components/success-message/SuccessBox'

export default {
    name: 'Cases',
    props: ['filterStaffCases', 'showConfirmationBox'],
    data () {
        return {
            // Active buttons filter default
            activeFilterCase: 1,
            // Table filters
            filters: {
                name: { value: '', keys: ['victim.full_name', 'id'] },
                inProgress: { value: '', keys: ['status'] },
                registered: { value: '', keys: ['status'] },
                yours: { value: '', keys: ['status'] }
            },
            // Pagination default
            currentPage: 1,
            totalPages: 0,
            pageSize: 20,
            pageSizeIncrement: null,
            currentPageSize: null,
            page: null
        }
    },
    // Fetch all cases from vuex
    computed: {
        ...mapGetters(['cases', 'userTypeManager', 'userTypeStaff', 'token'])
    },
    methods: {
        ...mapActions(['getCases', 'eraseErrors']),
        // Return values for sorting and filtering
        name (cases) {
            return cases.victim.full_name
        },
        status (cases) {
            return cases.status
        },
        // Show all cases
        filterAllCases (el) {
            this.filters.registered.value = ''
            this.filters.inProgress.value = ''
            this.filters.yours.value = ''
            this.activeFilterCase = el
        },
        // Show only registerd case
        filterRegisteredCases (item, el) {
            this.filters.inProgress.value = ''
            this.filters.yours.value = ''
            this.filters.registered.value = item
            this.activeFilterCase = el
        },
        // Show only in progress cases
        filterInProgressCases (item, el) {
            this.filters.registered.value = ''
            this.filters.yours.value = ''
            this.filters.inProgress.value = item
            this.activeFilterCase = el
        },
        // Show only in progress cases
        filterYourCases (item, el) {
            this.filters.registered.value = ''
            this.filters.inProgress.value = ''
            this.filters.yours.value = item
            this.activeFilterCase = el
        },
        // Add css class based on database value for case status
        BindCaseStatus (status) {
            if (status === 'registered') {
                return 'bg-yellow-500 text-purple-600'
            } else if (status === 'in progress') {
                return 'bg-purple-500 text-white'
            } else if (status === 'archived') {
                return 'bg-gray-500 text-white'
            } else {
                return 'bg-gray-200 text-gray-800'
            }
        },

        /** Display info table in table footer method */
        getTableRecordsInfo () {
            this.currentPageSize = document.getElementById('table_body').childElementCount
            this.page = (this.pageSize * (this.currentPage - 1)) + 1

            if (this.cases.length < (this.pageSize * this.currentPage)) {
                this.pageSizeIncrement = (this.pageSize * (this.currentPage - 1)) + this.currentPageSize
            } else {
                this.pageSizeIncrement = (this.pageSize * this.currentPage)
            }
        }
    },
    updated () {
        this.getTableRecordsInfo()
    },
    mounted () {
        this.getCases({ token: this.token }).then(() => {
            this.getTableRecordsInfo()
        })
        if (this.filterStaffCases) {
            this.activeFilterCase = 4
            this.filters.yours.value = 'yours'
        }

        if (this.showConfirmationBox) {
            const SuccessBoxComponent = Vue.extend(SuccessBox)
            /** Dynamically mount success box to page */
            const instance = new SuccessBoxComponent({
                propsData: { statusTitle: this.$t('servicesPageRequestSuccessBoxTitle'), statusMessage: this.$t('newCaseMessageBox'), success: true }
            })
            instance.$mount()
            this.$refs.successBoxContainer.appendChild(instance.$el)
        }
    }
}
</script>
